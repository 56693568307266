import React, { useState } from "react";
import useCategories from "../../customHooks/useCategories";
import { useNavigate } from "react-router-dom";

const FilterOptions = ({
  products_length,
  selectedCategory,
  setSelectedCategory, priceFilter, setPriceFilter,
  ratingFilter, setRatingFilter,
  navigation = false
}) => {


  const categories = useCategories();
  const navigate = useNavigate();

  const get_disabled = () => {
    if (products_length === 0) return false;
    return false;
  };

  return (
    <div className="p-5 px-8">
      {/* Popular Products Section */}
      <div className="mb-4">
        <h3 className="font-poppinsSemiBold text-[1.15rem] mb-2">
          Popular Products
        </h3>
        <div className="flex flex-col space-y-2">
          <label className="flex items-center space-x-2 font-poppinsMedium text-[0.88rem]">
            <input
              disabled={get_disabled()}
              type="radio"
              name="sort"
              value="desc"
              checked={priceFilter === "desc"}
              onChange={() => setPriceFilter("desc")}
              className="form-radio scale-[130%]"
            />
            <span>Price (Higher to lower)</span>
          </label>
          <label className="flex items-center space-x-2 font-poppinsMedium text-[0.88rem]">
            <input
              disabled={get_disabled()}
              type="radio"
              name="sort"
              value="Price (Lower to higher)"
              checked={priceFilter === "asc"}
              onChange={() => setPriceFilter("asc")}
              className="form-radio scale-[130%]"
            />
            <span>Price (Lower to higher)</span>
          </label>
        </div>
      </div>

      {/* Ratings Section */}
      <div className="mb-4">
        <h3 className="font-poppinsSemiBold text-[1rem] mb-2">Ratings</h3>
        <div className="flex flex-col space-y-2">
          <label className="flex items-center space-x-2 font-poppinsMedium text-[0.88rem]">
            <input
              disabled={get_disabled()}
              type="radio"
              name="rating"
              value="Rating (Higher to lower)"
              checked={ratingFilter === "desc"}
              onChange={() => setRatingFilter("desc")}
              className="form-radio text-blue-600 scale-[130%]"
            />
            <span>Rating (Higher to lower)</span>
          </label>
          <label className="flex items-center space-x-2 font-poppinsMedium text-[0.88rem]">
            <input
              disabled={get_disabled()}
              type="radio"
              name="rating"
              value="Rating (Higher to lower)"
              checked={ratingFilter === "asc"}
              onChange={() => setRatingFilter("asc")}
              className="form-radio text-blue-600 scale-[130%]"
            />
            <span>Rating (Lower to Higher)</span>
          </label>
        </div>
      </div>

      {/* Categories Section */}
      <div>
        <h3 className="font-poppinsSemiBold text-[1rem] mb-2">Categories</h3>
        <div className="flex flex-col space-y-2">
          {!navigation && <label className="flex items-center space-x-2">
            <input
              disabled={get_disabled()}
              type="radio"
              name="category"
              value={null}
              checked={selectedCategory === null}
              onChange={() => setSelectedCategory(null)}
              className="form-radio scale-[130%]"
            />
            <span className="font-poppinsMedium text-[0.88rem]">
              {"Featured"}
            </span>
          </label>}
          {categories.map((category) => (
            <label key={category?.id} className="flex items-center space-x-2">
              <input
                disabled={get_disabled()}
                type="radio"
                name="category"
                value={category?.id}
                checked={selectedCategory === category?.id}
                onChange={() => { 
                  setSelectedCategory(category?.id)
                  if(navigation){
                    navigate(`/products/${category.id}`, {state:{name:category.name}})
                  }
                 }}
                className="form-radio scale-[130%]"
              />
              <span className="font-poppinsMedium text-[0.88rem]">
                {category?.name}
              </span>
            </label>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FilterOptions;
