import { fetchQuery } from "./helpers";

const categoryFilterQuery = `query MyQuery($_eq: uuid = "", $price: order_by = asc, $rating: order_by = asc) {
  product(where: {category_id: {_eq: $_eq}}, order_by: {price: $price, rating: $rating}) {
    id
    name
    price
    image_urls
    product_details
    rating
    stock
    category {
      name
    }
    super_market {
      id
      name
    }
  }
}
`;

const categoryFilterQueryWithStore = `query MyQuery($_eq: uuid = "", $_eq1: uuid = "", $price: order_by = asc, $rating: order_by = asc) {
  product(where: {category_id: {_eq: $_eq}, super_market: {id: {_eq: $_eq1}}}, order_by: {price: $price, rating: $rating}) {
    id
    name
    price
    image_urls
    product_details
    rating
    stock
    category {
      name
    }
    super_market {
      id
      name
    }
  }
}
`

export const CategoryProducts = async (category_id, store_id, price, rating) => {
  try {
    let query;
    const variables = {
      _eq:category_id,
      price:price, rating:rating
    }
    if(store_id){
      variables["_eq1"] = store_id
      query = categoryFilterQueryWithStore
    }else{
      query = categoryFilterQuery;
    }
    const data = await fetchQuery(query, variables);
    return data.product;
  } catch (error) {
    throw error;
  }
};


const productByIdQuery = `query MyQuery($id: uuid = "") {
  product_by_pk(id: $id) {
    id
    name
    image_urls
    price
    product_details
    rating
    super_market {
      id
      name
    }
  }
}`

export const GetProduct = async(product_id) => {
  try {
    const data = await fetchQuery(productByIdQuery, {
      id:product_id
    })
    return data.product_by_pk
  } catch (error) {
    throw error
  }
}
