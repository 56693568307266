import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
// import { Heart, Shield, Share2, ShoppingCart } from 'lucide-react';
// import { close_product_description } from "../../redux/productDescriptionSlice";
import { open_login } from "../../redux/loginModalSlice";
import { AddCartItem, CreateCart } from "../../api/cart";
import {
  add_item,
  set_cart_id,
  set_name,
  set_vendor_id,
} from "../../redux/cartSlice";
// import Counter from "../Counter/Counter";
import toast from "react-hot-toast";
import { Link, useParams } from 'react-router-dom';
import images from '../../images';
import Counter from '../../components/Counter/Counter';
import { GetProduct } from '../../api/products';
// import ImageMagnifier from '../../components/ImageMagnifier/ImageMagnifier';

// ImageMagnifier component as provided earlier
const ImageMagnifier = ({
  url,
  zoomLevel = 1.5,
  width,
  height,
  magnifierWidth,
  magnifierHeight,
}) => {
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [show, setShow] = useState(false);
  const [size, setSize] = useState({ width: 0, height: 0 });

  const handleMouseEnter = (e) => {
    setShow(true);
    const el = e.currentTarget;
    const { width, height } = el.getBoundingClientRect();
    setSize({ width, height });
  };

  const handleMouseMove = (e) => {
    const el = e.currentTarget;
    const { top, left } = el.getBoundingClientRect();
    const x = e.pageX - left - window.scrollX;
    const y = e.pageY - top - window.scrollY;
    setPosition({ x, y });
  };

  return (
    <div className="w-auto mx-auto h-full relative">
      <img
        width={width}
        height={height}
        onMouseLeave={() => setShow(false)}
        onMouseEnter={handleMouseEnter}
        onMouseMove={handleMouseMove}
        src={url}
        className="object-contain mix-blend-multiply"
        alt="Product view"
      />

      {show && (
        <div
          style={{
            width: `${magnifierWidth}px`,
            height: `${magnifierHeight}px`,
            pointerEvents: "none",
            top: position.y - magnifierHeight / 2,
            left: position.x - magnifierWidth / 2,
            opacity: 1,
            backgroundColor: "white",
            backgroundImage: `url(${url})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: `${size.width * zoomLevel}px ${size.height * zoomLevel}px`,
            backgroundPositionX: `${-position.x * zoomLevel + magnifierWidth / 2}px`,
            backgroundPositionY: `${-position.y * zoomLevel + magnifierHeight / 2}px`,
          }}
          className="bg-white absolute top-0 border border-gray"
        />
      )}
    </div>
  );
};

const ProductPage = () => {
  const [currentImage, setCurrentImage] = useState(0);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const { productId } = useParams();

  // Redux selectors
  const [product, setProduct] = useState(null) //useSelector((state) => state.product_description?.product);
  const count = useSelector((state) => state.cart?.cartObj[product?.id]?.count || 0);
  const cart_id = useSelector((state) => state.cart?.cart_id);
  const shop_id = useSelector((state) => state.cart?.vendor_id);
  const user_id = useSelector((state) => state.auth.user_id);



  const handleAdd = async (e) => {
    e.stopPropagation();
    if (!user_id) {
      return dispatch(open_login());
    }
    try {
      setLoading(true);
      let newCartId = null;
      if (!cart_id) {
        newCartId = await CreateCart(product?.super_market.id, user_id);
        dispatch(set_cart_id(newCartId));
      }
      if (!shop_id) {
        dispatch(set_vendor_id(product?.super_market?.id));
      } else if (product?.super_market.id !== shop_id) {
        return toast.error("Only one Shop is Allowed.");
      }
      await AddCartItem(product?.super_market.id, cart_id || newCartId, {
        ...product,
        count: 1,
      });
      dispatch(add_item({ ...product, count: 1 }));
      dispatch(set_name(product?.super_market?.name));
      toast.success("Item added");
    } catch (error) {
      toast.error("Unable to Add.");
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const get_product = async () => {
    try {
      const data = await GetProduct(productId)
      setProduct(data)
      console.log("Product info", data)
    } catch (error) {
      console.log(error);
      toast.error("Unable to get details.", { id: "UNABLE_TO_GET" })
    }
  }

  useEffect(() => {
    if (productId) {
      get_product()
    }
  }, [productId])

  //   if (!product) return null;

  return (
    <div className="min-h-screen bg-white max-md:mb-20">
      {/* <div className="max-w-7xl mx-auto px-4"> */}
      <div className="bg-white">
        <div className="grid grid-cols-1 md:grid-cols-2">
          {/* Left Column - Image Gallery */}
          <div className="p-6 border-r border-gray-200">
            <div className="space-y-4">
              {/* Main Image with Magnifier */}
              <div className="bg-white rounded-lg overflow-hidden flex justify-center">
                <ImageMagnifier
                  url={product?.image_urls[currentImage]}
                  zoomLevel={2}
                  width={"500px"}
                  height={"500px"}
                  magnifierWidth={200}
                  magnifierHeight={200}
                />
              </div>

              {/* Thumbnails */}
              <div className="flex space-x-4 overflow-x-auto  p-2">
                {product?.image_urls.map((url, idx) => (
                  <button
                    key={idx}
                    onClick={() => setCurrentImage(idx)}
                    className={`flex-shrink-0 w-20 h-20 rounded-lg overflow-hidden ${currentImage === idx ? 'ring-2 ring-blue-500' : 'ring-1 ring-gray-200'
                      }`}
                  >
                    <img
                      src={url}
                      alt={`${product.name} view ${idx + 1}`}
                      className="w-full h-full object-cover mix-blend-multiply"
                    />
                  </button>
                ))}
              </div>
            </div>
          </div>

          {/* Right Column - Product Details */}
          <div className="p-6">
            <div className="space-y-6">
              {/* Product Title */}
              <div className="cutoff-text font-poppinsMedium text-[1.25rem]">
                {product?.name}
              </div>
              {/* Price and Delivery Time */}
              <div className="flex gap-3 items-center">
                <div className="font-inter font-medium text-[0.88rem] px-4 py-1 rounded-full bg-[#EBEEF2C2] border border-[#00000038]">
                  8 min
                </div>
                <div className="font-poppinsSemiBold text-[1.25rem]">
                  AED {product?.price}
                </div>
                <div className='md:hidden ms-auto'>
                  {count === 0 && (
                    <button
                      disabled={loading}
                      onClick={handleAdd}
                      className={`text-[#217229] ${loading && "text-gray-300 border-gray-300"
                        } border border-[#217229] px-6 py-2 rounded-lg text-[0.85rem] font-poppinsMedium`}
                    >
                      {loading ? "Loading..." : "ADD"}
                    </button>
                  )}
                  {count > 0 && (
                    <div className="max-sm:scale-75">
                      <Counter count={count} loading={loading} product={product} />
                    </div>
                  )}
                </div>
              </div>

              {/* Store Name */}
              <div className="bg-[#D0FFDB4F] p-3 px-5 rounded-lg">
                <Link
                  // onClick={handleClose}
                  to={`/store/${product?.super_market.id}`}
                  className="underline underline-offset-8 font-poppinsMedium text-[1rem] text-[#217229]"
                >
                  {product?.super_market?.name || "-"}
                </Link>
                <div className="flex items-center gap-3 mt-2">
                  <div className="flex items-center gap-1">
                    <img src={images.star} className="w-[15px]" alt="rating" />
                    <span className="font-poppinsMedium text-[0.88rem]">
                      {product?.rating || "-"}
                    </span>
                  </div>
                </div>
              </div>




              {/* Product Details */}
              {/* <div className="space-y-4">
                  <h3 className="font-inter font-medium text-[1rem]">Product Description</h3>
                  <p className="font-inter font-light text-[1rem] text-[#666666]">
                    {product?.product_details?.["Key Features"] || "-"}
                  </p>
                </div> */}
              <div className="mt-3">
                <div className="font-inter font-medium text-[1rem]">
                  Product Description
                </div>
                <div className="mt-1 font-inter font-light text-[1rem] text-[#666666]">
                  <div>{product?.product_details?.description}</div>
                </div>
                {product?.product_details && 
                  Object.keys(product?.product_details)?.map((key) => {
                    if(key === "description") return null
                    return <div className="mt-1 font-inter font-light text-[1rem] text-[#666666]">
                      <div>{product?.product_details[key]}</div>
                    </div>
})
                }
              </div>

              {/* Additional Details */}


              {/* Add to Cart Section */}
              <div className="fixed bottom-0 max-md:hidden left-0 right-0 md:relative bg-white p-4 border-t md:border-t-0 shadow-lg md:shadow-none">
                <div className="max-w-7xl mx-auto flex justify-between items-center">
                  <div className="flex gap-2 items-center">
                    <img
                      src={product?.image_urls[0]}
                      className="w-[50px] mix-blend-multiply"
                      alt={product?.name}
                    />
                    <div>
                      <div className="font-inter font-semibold text-[1rem]">
                        AED {product?.price}
                        {product?.product_details.weight && (
                          <span className="font-inter font-medium text-[0.88rem] text-black">
                            {" "}- {product?.product_details?.weight}
                          </span>
                        )}
                      </div>
                      <div className="font-inter font-light text-[0.88rem] text-[#00000066]">
                        Inclusive of all taxes
                      </div>
                    </div>
                  </div>
                  <div>
                    {count === 0 && (
                      <button
                        disabled={loading}
                        onClick={handleAdd}
                        className={`text-[#217229] ${loading && "text-gray-300 border-gray-300"
                          } border border-[#217229] px-6 py-2 rounded-lg text-[0.85rem] font-poppinsMedium`}
                      >
                        {loading ? "Loading..." : "ADD"}
                      </button>
                    )}
                    {count > 0 && (
                      <div className="max-sm:scale-75">
                        <Counter count={count} loading={loading} product={product} />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* </div> */}
    </div>
  );
};

export default ProductPage;