import React, { lazy, Suspense, useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import CartWindow from "./components/CartWindow";
import CheckoutPage from "./components/CheckoutPage";
import SignUp from "./pages/SignUp/SignUp";
import Vendor from "./pages/Vendor/Vendor";
import ProductsByCategory from "./pages/ProductsByCategory/ProductsByCategory";
import CheckoutModal from "./components/CheckoutModal/CheckoutModal";
import { useSelector } from "react-redux";
import { Toaster } from "react-hot-toast";
import ProductPage from "./pages/Product/Product";

const AppLayout = lazy(() => import("./Layouts/AppLayout/AppLayout"));
const OnboardLayout = lazy(() =>
  import("./Layouts/OnboardLayout/OnboardLayout")
);
const Home = lazy(() => import("./pages/Home"));
const NearbyVendors = lazy(() => import("./pages/NearbyVendors/NearbyVendors"));
const ProfileOverview = lazy(() =>
  import("./pages/ProfileOverview/ProfileOverview")
);
const Profile = lazy(() => import("./pages/Profile/Profile"));
const FavouriteStores = lazy(() =>
  import("./pages/FavouriteStores/FavouriteStores")
);
const TrendingProducts = lazy(() =>
  import("./pages/TrendingProducts/TrendingProducts")
);
const Categories = lazy(() => import("./components/Categories"));

const SearchResult = lazy(() => import("./pages/SearchResult/SearchResult"));
const YourOrders = lazy(() => import("./pages/YourOrders/YourOrders"));
const OrderDetails = lazy(() => import("./pages/OrderDetails/OrderDetails"));
const EditProfile = lazy(() => import("./pages/EditProfile/EditProfile"));
const Locations = lazy(() => import("./pages/Locations/Locations"));

const App = () => {
  const isCartOpen = useSelector((state) => state.showCart.showCart);
  const isCheckoutOpen = useSelector(
    (state) => state.showCheckout.showCheckout
  );

  return (
    <>
      <Toaster />
      <div className="min-w-[320px] relative">
        <Suspense fallback={fb()}>
          <Router>
            <Routes>
              <Route path="/" element={<AppLayout />}>
              <Route path="/product/:productId" element={<ProductPage />} />
                <Route index element={<Home />} />
                <Route path="nearby-vendors" element={<NearbyVendors />} />
                <Route
                  path="trending-products"
                  element={<TrendingProducts />}
                />
                <Route path="checkout" element={<CheckoutPage />} />
                <Route path="categories" element={<Categories />} />
                <Route
                  path="products/:categoryId"
                  element={<ProductsByCategory />}
                />
                {/* Vendor/Supermarket */}
                <Route path="store/:store_id" element={<Vendor />} />
                {/* Favourite Vendors */}
                <Route path="favourite-stores" element={<FavouriteStores />} />

                {/* Profile */}
                <Route path="profile" element={<Profile />}>
                  {/* Default component in profile page */}
                  <Route index element={<ProfileOverview />} />
                  <Route path="order-history" element={<YourOrders />} />
                  <Route
                    path="order-history/order/:order_id"
                    element={<OrderDetails />}
                  />
                  <Route path="edit-profile" element={<EditProfile />} />
                  <Route path="your-locations" element={<Locations />} />
                </Route>
                <Route path="/s" element={<SearchResult />} />
              </Route>
              <Route path="/sign-up" element={<OnboardLayout />}>
                <Route index element={<SignUp />} />
              </Route>
              <Route
                path="*"
                element={
                  <div className="text-[3rem] h-screen flex justify-center items-center">
                    404 <br /> PAGE NOT FOUND
                  </div>
                }
              />
            </Routes>

            {isCartOpen && <CartWindow />}
            {isCheckoutOpen && <CheckoutModal />}
          </Router>
        </Suspense>
      </div>
    </>
  );
};

function fb() {
  return (
    <h1 className="h-screen flex justify-center items-center text-2xl">
      Loading...
    </h1>
  );
}

export default App;
