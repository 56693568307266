import React, { useState, useEffect } from "react";
import { Link, useLocation, useParams } from "react-router-dom";

import CustomSelect from "../../components/CustomSelect/CustomSelect";
import { IoIosArrowRoundBack } from "react-icons/io";
import ProductCard from "../../components/ProductCard/ProductCard";
import toast from "react-hot-toast";
import { CategoryProducts } from "../../api/products";
import useSupermarkets from "../../customHooks/useSupermarkets";
import FilterOptions from "../../components/FilterOptions/FilterOptions";
import images from "../../images";
import { CheckFavCategory, CreateFavCategory, DeleteFavCategory } from "../../api/categories";
import { useDispatch, useSelector } from "react-redux";
import { open_login } from "../../redux/loginModalSlice";
// import ProductCard from "./ProductCard";
// import CustomSelect from "./CustomSelect";
// import images from "../images";
// import productsData from "../data/productsData";
// import ProductCard from "./ProductCard/ProductCard";

const ProductsByCategory = () => {
  const { categoryId } = useParams();
  const user_id = useSelector((state) => state.auth.user_id)
  const supermarkets = useSupermarkets();
  const [selectedStore, setSelectedStore] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const location = useLocation();
  const { name } = location?.state
  const [products, setProducts] = useState([]);
  const [supermarketOptions, setSupermarketOptions] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [priceFilter, setPriceFilter] = useState("asc")
  const [ratingFilter, setRatingFilter] = useState("asc")
  const [saved, setSaved] = useState(false)
  const dispatch = useDispatch();

  const get_supermarket_options = () => {
    const options = supermarkets?.map((m) => {
      const label = m?.name;
      const value = m?.id;
      return { label, value };
    });
    setSupermarketOptions([{ label: "Featured", value: null }, ...options]);
  };

  useEffect(() => {
    get_supermarket_options();
    
  }, [supermarkets]);

  useEffect(() => {
    if(user_id){
      CheckFavCategory(categoryId, user_id)
      .then((isFav) => {
        if(isFav){
          setSaved(true)
        }else{
          setSaved(false)
        }
      })
    }
    
  },[categoryId, user_id])

  const get_products = async () => {
    try {
      setLoading(true);
      const products = await CategoryProducts(categoryId, selectedStore, priceFilter, ratingFilter);
      setProducts(products);
    } catch (error) {
      toast.error("Unable to fetch.");
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  async function handleFav() {
    try {
      if (!user_id) return dispatch(open_login());
      if(!saved){
        const res = await CreateFavCategory(categoryId, user_id);
        if(res){
          return toast.error(res.message, {id:"RES"});
        }
        toast.success("Added to Fav.")
        setSaved(true)
      }else{
        await DeleteFavCategory(categoryId, user_id)
        toast.success("Deleted", {id:"DELETED"})
        setSaved(false)
      }
    } catch (error) {
      console.log(error)
      toast.error("Unable to Mark", {id:"MARK_FAILED"})
    }
  }

  useEffect(() => {
    get_products();

  }, [selectedStore, categoryId, priceFilter, ratingFilter]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="bg-[#F5F2F7] max-sm:h-auto max-sm:pb-[80px] max-sm:px- h-full flex">
      <div className=" flex-shrink-0 max-sm:hidden overflow-y-scroll bg-white">
        <FilterOptions
          priceFilter={priceFilter}
          setPriceFilter={setPriceFilter}
          ratingFilter={ratingFilter}
          setRatingFilter={setRatingFilter}
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
          navigation={true}
        // products_length={shopData?.products?.length}
        />
      </div>
      {/* Title and filters */}
      <div className="flex-grow p-4 bg-[#F5F2F7] max-sm:h-auto max-sm:pb-[80px] max-sm:px-2">
        <div className="flex justify-between mb-4 max-sm:flex-col">
          <h2 className="font-poppinsSemiBold text-[1rem] flex items-center gap-2">
            <Link to={"/"}>
              <IoIosArrowRoundBack size={30} />
            </Link>
            <span>{name}</span>
            <span className="cursor-pointer" onClick={handleFav}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill={saved ? "red" : "none"}
                viewBox="0 0 24 24"
                stroke-width={saved ? "0" : "1.5"}
                stroke="currentColor"
                class="size-7"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12Z"
                />
              </svg>
            </span>
          </h2>
          <div className="flex gap-3 max-sm:mt-3 lg:w-[300px]">
            <CustomSelect
              bg={"white"}
              radius={"10px"}
              placeholder={"Vendors"}
              options={supermarketOptions}
              changeValue={setSelectedStore}
              value={selectedStore}
            />
          </div>
        </div>
        {/* Content */}
        <div className="mt-5 w-full max-h-[73vh] overflow-y-auto">
          <div className="flex max-md:gap-1 md:gap-2 flex-wrap max-sm:justify-around">
            {products?.length > 0 ? (
              products?.map((product) => (
                <div
                  className="basis-[14rem] max-sm:basis-[11.5rem] max-[375px]:basis-[10rem] max-sm:flex-grow flex-shrink-0"
                  key={product.id}
                >
                  <ProductCard
                    product={product}
                    name={product?.super_market?.name}
                    vendor_id={product?.super_market.id}
                    showOffer={false}
                  />
                </div>
              ))
            ) : (
              <div>No products found.</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductsByCategory;
