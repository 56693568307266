import { fetchMutation, fetchQuery } from "./helpers";

const categoriesQuery = `query MyQuery {
  category {
    id
    name
    image_url
  }
}
`;

export const GetCategories = async () => {
  try {
    const data = await fetchQuery(categoriesQuery);
    // console.log("categories", data.category);
    return data.category;
  } catch (error) {
    throw error;
  }
};

const markFavCategory = `mutation MyMutation($category_id: uuid = "", $user_id: uuid = "") {
  insert_favourite_category_one(object: {category_id: $category_id, user_id: $user_id}) {
    id
  }
}`

const countOfFav = `query MyQuery($_eq: uuid = "") {
  favourite_category(where: {user_id: {_eq: $_eq}}) {
    category {
      id
      name
      image_url
    }
  }
}

`
export const GetFavCategories = async(user_id) => {
  try {
    const data = await fetchQuery(countOfFav, {
      _eq:user_id
    })
    return data.favourite_category
  } catch (error) {
    throw error
  }
}

const deleteFav = `mutation MyMutation($_eq: uuid = "", $_eq1: uuid = "") {
  delete_favourite_category(where: {category_id: {_eq: $_eq}, user_id: {_eq: $_eq1}}) {
    returning {
      id
    }
  }
}
`

const checkFav = `query MyQuery($_eq: uuid = "", $_eq1: uuid = "") {
  favourite_category(where: {user_id: {_eq: $_eq}, category_id: {_eq: $_eq1}}) {
    id
  }
}`

export const CheckFavCategory = async(category_id, user_id) => {
  try {
    const data = await fetchQuery(checkFav, {
      _eq:user_id,
      _eq1: category_id
    })
    return data.favourite_category.length > 0;
  } catch (error) {
    throw error
  }
}

export const CreateFavCategory = async (category_id, user_id) => {
  try {
    // check if max limit achieved then return.
    let response ={}
    const countData = await fetchQuery(countOfFav, {
      _eq:user_id,
    })
    if(countData.favourite_category.length >= 5){
      response.message = "Max limit 5"
      return  response
    }
    // if status is true then check if already marked true in db33333333

      const data = await fetchQuery(checkFav, {
        _eq:user_id,
        _eq1: category_id
      })
      if(data.favourite_category.length > 0){
        response.message = "Already Marked";
        return response
      }
      await fetchMutation(markFavCategory, {
        category_id:category_id,
        user_id:user_id,
      })
    // if status is false then delete that record
  } catch (error) {
    throw error
  }
}

export const DeleteFavCategory = async(category_id, user_id) => {
  try {
    await fetchMutation(deleteFav, {
      _eq:category_id,
      _eq1:user_id
    })
  } catch (error) {
    throw error
  }
}
