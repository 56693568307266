import React, { useEffect, useRef, useState } from "react";
import FilterOptions from "../../components/FilterOptions/FilterOptions";
import VendorDetails from "../../components/VendorDetails/VendorDetails";
// import ProductCard from "../../components/ProductCard";
import FrequentBuysProductCard from "../../components/ProductCard/ProductCard";
import { GetSupermarketProducts } from "../../api/supermarkets";
import { useParams } from "react-router-dom";
import CustomSelect from "../../components/CustomSelect/CustomSelect";
import useCategories from "../../customHooks/useCategories";

function Vendor() {
  const [shopData, setShopData] = useState(null);
  const { store_id } = useParams();
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [priceFilter, setPriceFilter] = useState("asc")
  const [ratingFilter, setRatingFilter] = useState("asc")
  const categories = useCategories();
  const productsContainerRef = useRef(null);

  const get_category_options = () => {
    const firstOption = {
      label: "Featured",
      value: null,
    };
    const options = categories?.map((c) => {
      const label = c.name;
      const value = c.id;
      return { label, value };
    });
    return [firstOption, ...options] || [];
  };

  const get_shop_data = async () => {
    try {
      const data = await GetSupermarketProducts(store_id, selectedCategory, priceFilter, ratingFilter);
      // console.log("data filter", data?.products.length);
      setShopData({ ...data });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    get_shop_data();
  }, [selectedCategory, priceFilter, ratingFilter]);


  return (
    <div className=" h-full flex max-md:mb-20">
      {/* Filter Menu to filter products in Supermarket */}
      <div className="border-e-2 border-[#00000029] flex-shrink-0 max-sm:hidden overflow-y-scroll">
        <FilterOptions
          priceFilter={priceFilter}
          setPriceFilter={setPriceFilter}
          ratingFilter={ratingFilter}
          setRatingFilter={setRatingFilter}
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
          products_length={shopData?.products?.length}
        />
      </div>
      <div className="flex-grow  flex flex-col">
        <div className="mt-5 w-[95%] flex-grow mx-auto flex flex-col ">
          <div className="">
            {/* Vendor rating, service fee etc. Meta data of vendor page */}
            <VendorDetails
              super_market_id={shopData?.id}
              name={shopData?.name}
            />
          </div>
          <div className="font-poppinsMedium text-[0.85rem] mt-2 md:hidden">
            <CustomSelect
              options={get_category_options()}
              placeholder={"Category"}
              radius={"8px"}
              value={selectedCategory}
              changeValue={setSelectedCategory}
            />
          </div>
          {/* All the Products of the Vendor/Supermarket */}
          {shopData?.products?.length > 0 && (
            <div
              ref={productsContainerRef}
              className="flex-grow max-h-[68vh] mt-3 gap-y-3 gap-x-1 overflow-y-scroll
               flex flex-wrap items-start justify-start max-sm:justify-start max-sm:mb-[100px]"
            >
              {shopData?.products?.map((product, idx) => {
                return (
                  <div
                    key={product?.id}
                    className="basis-[220px] max-sm:basis-[10rem] border border-[#E6EDF3] rounded-xl max-sm:flex-grow max-sm:max-w-[50%]"
                  >
                    <FrequentBuysProductCard
                      product={product}
                      name={shopData?.name}
                      vendor_id={shopData?.id}
                    />
                  </div>
                );
              })}
            </div>
          )}
          {shopData?.products?.length === 0 && (
            <div className="w-full text-center my-auto text-[2rem] font-poppinsMedium">
              No Products.
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Vendor;
