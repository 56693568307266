import { fetchMutation, fetchQuery } from "./helpers";

const order = `mutation MyMutation($user_id: uuid = "", $cart_id: uuid = "", $super_market_id: uuid = "", $address: jsonb = "") {
  insert_order_one(object: {user_id: $user_id, cart_id: $cart_id, super_market_id: $super_market_id, address: $address}) {
    id
  }
}`;

// Mutation to mark is_order_placed in cart to true
const updateCart = `mutation MyMutation($id: uuid = "", $is_order_placed: Boolean = false) {
  update_cart_by_pk(pk_columns: {id: $id}, _set: {is_order_placed: $is_order_placed}) {
    id
  }
}
`;

export const CreateOrder = async (user_id, cart_id, super_market_id, address) => {
  try {
    const data = await fetchMutation(order, {
      user_id: user_id,
      cart_id: cart_id,
      super_market_id: super_market_id,
      address:address
    });
    if (data) {
      await fetchMutation(updateCart, {
        id: cart_id,
        is_order_placed: true,
      });
    }
    return;
  } catch (error) {
    throw error;
  }
};

const getOrdersQuery = `query MyQuery($_eq: uuid = "") {
  order(where: {user_id: {_eq: $_eq}, cart: {is_order_placed: {_eq: true}}}, order_by: {created_at: desc}) {
    id
    order_status
    created_at
    cart {
      cart_items {
        count
        product {
          image_urls
          price
        }
      }
    }
  }
}

`;

export const GetOrders = async (user_id) => {
  try {
    const data = await fetchQuery(getOrdersQuery, {
      _eq: user_id,
    });
    return data.order;
  } catch (error) {
    throw error;
  }
};

const orderDetailsQuery = `query MyQuery($_eq: uuid = "", $_eq1: uuid = "") {
  order(where: {user_id: {_eq: $_eq}, id: {_eq: $_eq1}}) {
    id
    address
    created_at
    cart {
      cart_items {
        product {
          id
          name
          price
          image_urls
          product_details
        }
        count
      }
    }
    super_market {
      name
    }
  }
}

`;

export const GetOrderDetails = async (order_id, user_id) => {
  try {
    const data = await fetchQuery(orderDetailsQuery, {
      _eq: user_id,
      _eq1: order_id,
    });
    return data.order[0];
  } catch (error) {
    throw error;
  }
};
