import { createSlice } from "@reduxjs/toolkit";

const cart = JSON.parse(localStorage.getItem("cart"));

const initialState = {
  cart_id: cart?.cart_id || null,
  vendor_id: cart?.vendor_id || null,
  cartObj: cart?.cartObj || {},
  cartArr: cart?.cartArr || [],
  vendor_name: cart?.vendor_name || null,
};

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    set_cart_id: (state, action) => {
      state.cart_id = action.payload;
      localStorage.setItem("cart", JSON.stringify(state));
    },
    set_name: (state, action) => {
      if (!state.vendor_name) {
        state.vendor_name = action.payload;
        localStorage.setItem("cart", JSON.stringify(state));
      }
    },
    set_vendor_id: (state, action) => {
      state.vendor_id = action.payload;
      localStorage.setItem("cart", JSON.stringify(state));
    },
    add_item: (state, action) => {
      const product = action.payload;
      state.cartObj[product.id] = product;
      state.cartArr.push(product?.id);
      localStorage.setItem("cart", JSON.stringify(state));
    },
    increase_count: (state, action) => {
      const product_id = action.payload;
      state.cartObj[product_id].count = state.cartObj[product_id]?.count + 1;
      localStorage.setItem("cart", JSON.stringify(state));
    },
    decrease_count: (state, action) => {
      const product_id = action.payload;
      state.cartObj[product_id].count = state.cartObj[product_id]?.count - 1;
      if (state.cartObj[product_id].count === 0) {
        delete state.cartObj[product_id];
        state.cartArr = state.cartArr.filter((id) => id !== product_id);
      }

      if (state.cartArr.length == 0) {
        state.vendor_id = null;
        state.vendor_name = null;
        state.cart_id = null
      }

      localStorage.setItem("cart", JSON.stringify(state));
    },
    initiate_cart_from_api: (state, action) => {
      // Reset cart
      state.cartObj = {};
      state.cartArr = [];
      state.cart_id = null;
      state.vendor_id = null;
      // -----------------------
      const cart = action.payload;
      state.cart_id = cart?.id;
      state.vendor_id = cart?.vendor_id;
      cart?.cart_items?.map((item) => {
        state.cartObj[item.product.id] = {
          ...item.product,
          count: item.count,
        };
        state.cartArr.push(item.product.id);

        state.vendor_name = item.product.super_market.name;
      });
      localStorage.setItem("cart", JSON.stringify(state));
    },
    remove_cart: (state) => {
      state.cart_id = null;
      state.vendor_id = null;
      state.cartObj = {};
      state.cartArr = [];
      state.vendor_name = null;
      localStorage.setItem("cart", JSON.stringify(state));
    },
  },
});

export const {
  set_cart_id,
  set_vendor_id,
  add_item,
  increase_count,
  decrease_count,
  initiate_cart_from_api,
  remove_cart,
  set_name,
} = cartSlice.actions;
export default cartSlice.reducer;
